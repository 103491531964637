const ProductPgData = {
  data: {
    MushroomType: [
      {
        id: "1",
        type: "Mushroom",
        Species: "King Pearl",
        Description:
          "The King Pearl Oyster mushroom, part of the Pleurotus eryngii species, boasts a regal appearance with its substantial, meaty cap and pearly white hue. Its robust, anise-like flavor makes it a gourmet delight, and its larger size and firm texture allow for versatile cooking methods. This Pleurotus eryngii variant adds a touch of sophistication to culinary creations.",
        Image: "KingPearl.png",
      },
      {
        id: "2",
        type: "Mushroom",
        Species: "Pearl Oyster",
        Description:
          "Pearl oysters, named for their shimmering caps, aren't just eye candy for gourmet dishes. These versatile fungi boast a mild, meaty flavor that elevates stir-fries and soups. But their hidden power lies in their potential to degrade pollutants and even grow on plastic, making them eco-warriors of the mushroom world.",
        Image: "Pearl.png",
      },
      {
        id: "3",
        type: "Mushroom",
        Species: "Lions Mane",
        Description:
          "The lion's mane mushroom, with its shaggy white cascades, isn't just a striking sight - it's a culinary and medicinal treat. Savored for its delicate, seafood-like flavor, it also holds promise for boosting brain health and immunity, making it a versatile wonder of the fungi kingdom.",
        Image: "LionsMane.png",
      },
      {
        id: "4",
        type: "Mushroom",
        Species: "Enoki",
        Description:
          "The enoki mushroom (Flammulina velutipes) distinguishes itself with its slender, elongated stems and small, delicate caps. Unlike the broader shapes of many mushrooms, enoki mushrooms have a unique appearance resembling small, white strands, making them stand out in both visual appeal and texture. Their mild, slightly fruity flavor adds a distinct touch to various dishes, and their crunchy texture makes them a popular choice in salads, soups, and Asian cuisine. ",
        Image: "Enoki.png",
      },
      {
        id: "5",
        type: "Mushroom",
        Species: "Pink Oyster",
        Description:
          "The pink oyster mushroom (Pleurotus djamor) stands out among its oyster mushroom counterparts due to its distinctive and vibrant pink hue. It offers a mild, anise-like taste and a tender, velvety texture when cooked, providing a unique culinary experience that sets it apart from the more common varieties.",
        Image: "Pink.png",
      },
      {
        id: "6",
        type: "Mushroom",
        Species: "Blue Oyster",
        Description:
          "The Blue Oyster Mushroom, belonging to the Pleurotus ostreatus species, is distinguished by its unique characteristics. Featuring a distinct blue hue and a fan-shaped cap, this mushroom adds sophistication to culinary creations. Notably, it possesses a subtle savory flavor and a tender texture, making it adaptable for sautéing and grilling. What sets it apart further is its meaty stem, contributing to its overall robustness. Whether incorporated into risottos, pasta dishes, or other recipes, the Blue Oyster Mushroom offers a refined and substantial addition to your culinary repertoire.",
        Image: "Blue.png",
      },
    ],
    TechHardware: [
      {
        id: "1",
        type: "Tech-Hardware",
        Species: "Auto-Bagger",
        Description:
          "This Auto-Bagger has revolutionized my mushroom block production, significantly reducing the time and effort required. It operates seamlessly by efficiently collecting two primary ingredients, soy hull and hardwood, from their respective hoppers. These ingredients are then precisely dispensed into the open bag, situated around the front funnel. As soon as the substrate in the bag reaches a cumulative weight of four pounds, the system intelligently shuts off the motors. It then adds precisely 6 pounds of water before shutting off once again. Voila! In just about 12 seconds, you have a perfectly crafted mushroom block.",
        Image: "AutoBagger.png",
      },
    ],
    Recipes: [
      {
        id: "1",
        Recipe: "Mushroom Soup",
        Image: "Soup.png",
        Description:
          "This is a fast and simple Hungarian recipe for onion soup. The addition of dry white wine makes it a bit unique, otherwise, it is pretty close to being a standard mushroom soup recipe.",
        Ingredients: [
          "2 tablespoons Butter",
          "1 Small Onion, approximately 1 cup, chopped",
          "1 tablespoon Chopped Garlic",
          "1/2 to 3/4 pound Coarsely Chopped Mushrooms",
          "3 sprigs Fresh Rosemary or 1/2 teaspoon Dried Rosemary",
          "3 cups Beef Stock",
          "1 cup Dry White Wine",
          "Salt and Pepper to taste",
        ],
        Preparation: [
          "Melt the butter in a medium-size stockpot.",
          "Add the onion and garlic, mix well, and cook over medium heat until the onion is translucent, about 10 minutes.",
          "Add the mushrooms and rosemary and cook 5 more minutes.",
          "Add the stock and the wine and season with salt and pepper to taste.",
          "Reduce the heat to medium-low, and cook, covered, for 30 minutes, stirring occasionally.",
        ],
        Note: "If a thicker consistency is desired, add 1 or more tablespoons of flour or corn starch to the soup at the beginning of the 30-minute cooking time. Mix well to avoid lumps.",
      },
      {
        id: "2",
        Recipe: "Mushroom Marinate",
        Image: "Marinate.png",
        Description:
          "These marinated mushrooms are tangy and flavorful, perfect as an appetizer or addition to salads and antipasto platters.",
        Ingredients: [
          "2/3 cup extra-virgin olive oil",
          "1/2 cup water",
          "Juice of 2 lemons (about 4 tablespoons)",
          "1 bay leaf",
          "2 cloves garlic, bruised",
          "6 whole peppercorns",
          "1/2 teaspoon salt",
          "1-1.5 pounds mushrooms",
        ],
        Preparation: [
          "Combine extra-virgin olive oil, water, lemon juice, bay leaf, garlic, peppercorns, and salt in a deep stainless steel skillet.",
          "Bring to a boil over medium heat. Reduce the heat to medium-low, cover, and simmer for 15 minutes.",
          "Strain the marinade and return the liquid to the skillet. Bring to a simmer over low heat.",
          "Add the mushrooms and cook, stirring, for 5 additional minutes.",
          "Let the mushrooms cool in the marinade, then remove them along with the marinade and chill in the refrigerator for 15 minutes.",
          "Drain the mushrooms and serve cold or at room temperature.",
        ],
        Note: "For added flavor, consider adding fresh herbs like thyme or rosemary to the marinade.",
      },
      {
        id: "3",
        Recipe: "Sautéed Mushrooms",
        Image: "Saute.png",
        Description:
          "This simple mushroom recipe is delicious as a side, appetizer, part of a salad, or even just a snack.",
        Ingredients: [
          "2 tablespoons olive oil",
          "1 tablespoon unsalted butter",
          "1 cup chopped Portobello mushrooms",
          "2 teaspoons dried oregano",
          "1 teaspoon garlic powder",
          "1 teaspoon steak seasoning rub (Optional)",
        ],
        Preparation: [
          "Heat olive oil and butter in a skillet over medium heat. Stir in mushrooms until well coated.",
          "Sprinkle oregano, garlic powder, and steak seasoning on mushrooms; stir to coat.",
          "Cook and stir mushrooms until lightly browned, about 5 minutes.",
        ],
        Note: "Feel free to get creative and add or take away ingredients",
      },
      {
        id: "4",
        Recipe: "Mushroom Fried Rice",
        Image: "Fried.png",
        Description:
          "This mushroom fried rice recipe is a delicious and easy-to-make dish that's perfect for a quick lunch or dinner.",
        Ingredients: [
          "2 cups cooked rice (preferably day-old rice)",
          "2 tablespoons sesame oil",
          "2 eggs, lightly beaten",
          "2 cups sliced mushrooms (any variety)",
          "1 cup diced carrots",
          "1 cup frozen peas",
          "4 green onions, chopped",
          "3 cloves garlic, minced",
          "3 tablespoons soy sauce",
          "1 tablespoon oyster sauce (optional)",
          "Salt and pepper to taste",
        ],
        Preparation: [
          "Heat 1 tablespoon of sesame oil in a large skillet or wok over medium heat.",
          "Add the beaten eggs and scramble until they are fully cooked. Remove from the skillet and set aside.",
          "Add the remaining tablespoon of sesame oil to the skillet. Stir in the garlic and cook until fragrant, about 1 minute.",
          "Add the mushrooms, carrots, and peas to the skillet. Cook, stirring occasionally, until the vegetables are tender, about 5-7 minutes.",
          "Stir in the cooked rice, scrambled eggs, soy sauce, and oyster sauce (if using). Cook, stirring constantly, until everything is heated through and well combined, about 3-5 minutes.",
          "Season with salt and pepper to taste.",
          "Garnish with chopped green onions before serving.",
        ],
        Note: "Feel free to add other vegetables or protein of your choice such as bell peppers, broccoli, or cooked chicken or shrimp.",
      },
      {
        id: "5",
        Recipe: "Buttered Mushrooms on Toast",
        Image: "toast.png",
        Description:
          "This dish features savory mushrooms cooked in butter and served on toasted bread, perfect for a comforting breakfast or light meal.",
        Ingredients: [
          "4 slices of bread (your choice of bread)",
          "2 tablespoons unsalted butter",
          "2 cups sliced mushrooms (any variety)",
          "2 cloves garlic, minced",
          "2 tablespoons fresh parsley, chopped",
          "Salt and pepper to taste",
        ],
        Preparation: [
          "Toast the slices of bread until golden brown and crispy. Set aside.",
          "In a skillet, melt the butter over medium heat.",
          "Add the minced garlic to the skillet and cook until fragrant, about 1 minute.",
          "Add the sliced mushrooms to the skillet and sauté until they are tender and golden brown, about 5-7 minutes.",
          "Season the mushrooms with salt and pepper to taste, and stir in the chopped parsley.",
          "To serve, place the toasted bread slices on plates and top each slice with the buttered mushrooms.",
          "Garnish with additional parsley if desired, and serve warm.",
        ],
        Note: "For extra flavor, you can add a splash of white wine or a squeeze of lemon juice to the mushrooms while they cook.",
      },
      {
        id: "6",
        Recipe: "Maple Mushrooms on Waffles",
        Image: "waffle.png",
        Description:
          "This delightful recipe combines savory sautéed mushrooms with sweet maple syrup over warm, fluffy waffles, creating a perfect balance of flavors.",
        Ingredients: [
          "4 frozen waffles (or homemade, if preferred)",
          "2 tablespoons unsalted butter",
          "2 cups sliced mushrooms (any variety)",
          "2 tablespoons maple syrup",
          "Salt and pepper to taste",
          "Chopped fresh parsley for garnish (optional)",
        ],
        Preparation: [
          "Toast the frozen waffles according to package instructions until golden brown and crispy. Keep warm.",
          "In a skillet, melt the butter over medium heat.",
          "Add the sliced mushrooms to the skillet and sauté until they are tender and golden brown, about 5-7 minutes.",
          "Drizzle the maple syrup over the mushrooms and stir to combine. Cook for an additional 1-2 minutes until the mushrooms are glazed and caramelized.",
          "Season the mushrooms with salt and pepper to taste.",
          "To serve, place a toasted waffle on each plate and top with the maple mushrooms.",
          "Garnish with chopped fresh parsley if desired.",
          "Serve immediately and enjoy!",
          "Optionally, serve with a side of yogurt for a creamy contrast to the savory mushrooms.",
        ],
        Note: "Feel free to add a sprinkle of shredded cheese or a dollop of sour cream for extra richness and flavor.",
      },
      {
        id: "7",
        Recipe: "Mushroom Topped Steak",
        Image: "Steak.png",
        Description:
          "This recipe features succulent steak topped with savory sautéed mushrooms, creating a mouthwatering dish perfect for a special dinner.",
        Ingredients: [
          "4 steaks of your choice (such as ribeye, sirloin, or filet mignon)",
          "Salt and black pepper to taste",
          "2 tablespoons olive oil",
          "2 tablespoons unsalted butter",
          "2 cups sliced mushrooms (any variety)",
          "2 cloves garlic, minced",
          "1/4 cup beef broth (or red wine for extra richness)",
          "2 tablespoons chopped fresh parsley for garnish (optional)",
        ],
        Preparation: [
          "Preheat your grill or skillet to high heat.",
          "Season the steaks generously with salt and black pepper on both sides.",
          "Drizzle olive oil over the steaks and rub to coat evenly.",
          "Place the steaks on the grill or skillet and cook to your desired level of doneness, about 4-5 minutes per side for medium-rare depending on thickness. Remove from heat and let rest.",
          "In a separate skillet, melt the butter over medium heat.",
          "Add the sliced mushrooms and minced garlic to the skillet. Sauté until the mushrooms are tender and golden brown, about 5-7 minutes.",
          "Deglaze the skillet with beef broth or red wine, scraping up any browned bits from the bottom of the pan. Allow the liquid to reduce slightly, about 2-3 minutes.",
          "To serve, place the cooked steaks on plates and top with the sautéed mushrooms.",
          "Garnish with chopped fresh parsley if desired.",
          "Serve immediately and enjoy!",
        ],
        Note: "For best results, ensure your steaks are at room temperature before cooking to ensure even cooking throughout. Adjust cooking time based on the thickness of your steaks and your desired level of doneness.",
      },
    ],
  },
};
export default ProductPgData;
